//import global API
import Api from '../../api/Api'

const donation = {

    //set namespace true
    namespaced: true,

    //state
    state: {

        //donationsNonLogin
        donationsNonLogin: {},
        donationsCara: {},

        //donations
        donations: [],

        //loadmore
        nextExists: false,
        nextPage: 0,

    },

    //mutations
    mutations: {

        //set state donations non login dengan data dari response 
        SET_DONATIONS_NON_LOGIN(state, donation) {
            state.donationsNonLogin = donation
        },

        //set state donations dengan data dari response 
        SET_DONATIONS(state, donations) {
            state.donations = donations
        },
        SET_DONATIONS_CARA(state, donations) {
            state.donationsCara = donations
        },
        //set state nextExists
        SET_NEXTEXISTS(state, nextExists) {
            state.nextExists = nextExists
        },

        //set state nextPage
        SET_NEXTPAGE(state, nextPage) {
            state.nextPage = nextPage
        },

        //set state campaigns dengan data dari response loadmore
        SET_LOADMORE(state, data) {
            data.forEach(row => {
                state.donations.push(row);
            });
        },

    },

    //actions
    actions: {

        //action getDonation
        getDonation({ commit }) {
            //get data token dan user
            const token = localStorage.getItem('token')
            if (token == null ||token == undefined) {
                //get data donations dengan local storage
                const email = localStorage.getItem('email')
                Api.post('/donationLocalStorage', {
                    email: email
                })
                .then(response => {

                    //commit ke mutation SET_DONATIONS dengan response data
                    commit('SET_DONATIONS', response.data.data.data)

                    if (response.data.data.current_page < response.data.data.last_page) {

                        //commit ke mutation SET_NEXTEXISTS dengan true
                        commit('SET_NEXTEXISTS', true)
                        //commit ke mutation SET_NEXTPAGE dengan current page + 1
                        commit('SET_NEXTPAGE', response.data.data.current_page + 1)
                    } else {
                        //commit ke mutation SET_NEXTEXISTS dengan false
                        commit('SET_NEXTEXISTS', false)
                    }
                }).catch(error => {
                    //show error log dari response
                    console.log(error)
                })
            }else {
            //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                //get data donations ke server
                Api.get('/donation')
                .then(response => {

                    //commit ke mutation SET_DONATIONS dengan response data
                    commit('SET_DONATIONS', response.data.data.data)
                    if (response.data.data.current_page < response.data.data.last_page) {

                        //commit ke mutation SET_NEXTEXISTS dengan true
                        commit('SET_NEXTEXISTS', true)
                        //commit ke mutation SET_NEXTPAGE dengan current page + 1
                        commit('SET_NEXTPAGE', response.data.data.current_page + 1)
                    } else {
                        //commit ke mutation SET_NEXTEXISTS dengan false
                        commit('SET_NEXTEXISTS', false)
                    }
                }).catch(error => {
                    //show error log dari response
                    console.log(error)
                })
            }
        },
        getDonationDetail({ commit }, slug) {

            //get data detail campaign ke server
            Api.get(`/donation/${slug}`)
                .then(response => {
                    commit('SET_DONATIONS_CARA', response.data.data)
                }).catch(error => {
                    //show error log dari response
                    console.log(error)
                })
        },
        //action getLoadMore
        getLoadMore({ commit }, nextPage) {
            //get data token dan user
            const token = localStorage.getItem('token')

            //set axios header dengan type Authorization + Bearer token
            Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

            //get data donations dengan parameter page ke server
            Api.get(`/donation?page=${nextPage}`)
                .then(response => {

                    //commit ke mutation SET_LOADMORE dengan response data
                    commit('SET_LOADMORE', response.data.data.data)

                    //console.log(response.data.data.data)

                    if (response.data.data.current_page < response.data.data.last_page) {

                        //commit ke mutation SET_NEXTEXISTS dengan true
                        commit('SET_NEXTEXISTS', true)

                        //commit ke mutation SET_NEXTPAGE dengan current page + 1
                        commit('SET_NEXTPAGE', response.data.data.current_page + 1)

                    } else {

                        //commit ke mutation SET_NEXTEXISTS dengan false
                        commit('SET_NEXTEXISTS', false)
                    }

                }).catch(error => {

                    //show error log dari response
                    console.log(error)

                })
        },

        //storeDonation
        storeDonation({ commit }, data) {

            //define callback promise
            return new Promise((resolve, reject) => {

                //get data token dan user
                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                //send data donatiion ke server
                Api.post('/donation', data)
                    .then(response => {
                        commit('')
                        resolve(response)
                    }).catch(error => {
                        //show error log dari response
                        reject(error.response.data)
                    })
            })
        },

        //storeDonationNon
        storeDonationNon({ commit }, data) {

            //define callback promise
            return new Promise((resolve, reject) => {

                //get data token dan user
                // const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                // Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                //send data donatiion ke server
                Api.post('/donation/non', data)
                    .then(response => {
                        // console.log('res ', response)
                        // console.log('res ', response.data.data)

                        commit('SET_DONATIONS_NON_LOGIN', response.data.data)
                        resolve(response)

                    }).catch(error => {

                        //show error log dari response
                        reject(error.response.data)

                    })

            })

        },

        //store Qurban
        storeQurban({ commit }, data) {
            //define callback promise
            return new Promise((resolve, reject) => {
                //get data token dan user
                const token = localStorage.getItem('token')
                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                //send data donatiion ke server
                Api.post('/donation/storeQurban', data)
                    .then(response => {
                        commit('')
                        resolve(response)
                    }).catch(error => {
                        //show error log dari response
                        reject(error.response.data)
                    })
            })
        },
        //storeDonationNon
        storeQurbanNon({ commit }, data) {
            //define callback promise
            return new Promise((resolve, reject) => {
                //get data token dan user
                // const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                // Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                //send data donatiion ke server
                Api.post('/donation/nonQurban', data)
                    .then(response => {
                        // console.log('res ', response)
                        // console.log('res ', response.data.data)
                        commit('SET_DONATIONS_NON_LOGIN', response.data.data)
                        resolve(response)
                    }).catch(error => {
                        //show error log dari response
                        reject(error.response.data)
                    })
            })
        },

        //storeDonationNon
        storeBillingDonationNon({ commit }, data) {
            localStorage.setItem('email', data.email)
            localStorage.setItem('endTime', Date.now() + 3600000);
            //define callback promise
            return new Promise((resolve, reject) => {
                //get data token dan user
                // const token = localStorage.getItem('token')
                //set axios header dengan type Authorization + Bearer token
                // Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                //send data donatiion ke server
                Api.post('/donation/createBillingNon', data)
                    .then(response => {
                        commit('SET_DONATIONS_NON_LOGIN', response.data.data)
                        resolve(response)
                    }).catch(error => {
                        //show error log dari response
                        alert("gagal, silahkan ganti cara metode donasi")
                        reject(error.response.data)
                    })
            })
        },

        //storeDonationNon


        //storeDonation
        storeBillingDonation({ commit }, data) {
            //define callback promise
            return new Promise((resolve, reject) => {
                //get data token dan user
                // const token = localStorage.getItem('token')
                //set axios header dengan type Authorization + Bearer token
                // Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                //send data donatiion ke server
                Api.post('/donation/createBillingLogin', data)
                    .then(response => {
                        // console.log('1',response);
                        commit('');
                        resolve(response);
                        // return response.data.donation_id;
                    }).catch(error => {
                        //show error log dari response
                        // console.log('Error:', error);
                        alert("gagal, silahkan ganti cara metode donasi")
                        reject(error.response.data)
                    })
            })
        },
        checkDonationStatus({ commit }, slug) {
            return new Promise((resolve, reject) => {
            //get data detail campaign ke server
                Api.get(`/donation/${slug}`)
                    .then(response => {
                        commit('');
                        resolve(response);
                    }).catch(error => {
                        //show error log dari response
                        reject(error.response.data)
                    })
            })
        },
        
    },

    //getters
    getters: {

    }

}

export default donation